import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useRouter } from 'next/router';
import routes from 'routes';
import Input from '@components/common/FormElements/Input';
import Link from 'next/link';
import Button from '@components/common/Button';

const Login = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { signIn, signInWithGoogle } = useAuth();
  const router = useRouter();

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    // parse query string parameter
    const { search } = window.location;
    const query = new URLSearchParams(search);

    const redirect = query.get("redirect");
    const withToken = query.get("withToken");

    if (redirect) {
      localStorage.setItem('redirect', JSON.stringify({ redirect, withToken }))
    }
  }, [])

  const onLogin = async (values: any) => {
    try {
      setLoading(true);
      setError('');
      await signIn(values.email, values.password);
      router.push(routes.HOME);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  return (
    <div className="relative overflow-hidden">
      <section className="bg-tg-bg">
        <div className="">
          <div className="">
            <div className="w-full fixed top-6 lef-3 z-[99999]">
              <Link href="/">
                <img src="/Logo.svg" className="h-[60px] fill-white cursor-pointer" alt="" />
              </Link>
            </div>
            <div className="flex flex-row-reverse justify-center">
              <div className="w-full lg:w-1/2 ml-48">
                <div className=" w-4/5 px-6 py-14 sm:p-[70px]  xl:px-[90px]">
                  <h2
                    className="
                  mb-4 text-lg font-bold !leading-[1.22] text-dark dark:text-white sm:text-[24px]"
                  >
                    Sign in to Viralocity
                  </h2>

                  <div className="flex flex-wrap justify-center">
                    <button
                      onClick={signInWithGoogle}
                      className=" flex w-full items-center justify-center rounded-full border border-stroke px-2 py-3 text-sm text-body-color hover:border-primary hover:text-primary dark:border-dark-3 dark:text-dark-6 sm:text-base lg:px-2 lg:text-sm xl:px-4 xl:text-base"
                    >
                      <span className="pr-3">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_1368_888)">
                            <path
                              d="M20.001 10.2216C20.0131 9.53416 19.9407 8.84776 19.7853 8.17725H10.2051V11.8883H15.8286C15.7221 12.539 15.4823 13.1618 15.1238 13.7194C14.7653 14.2769 14.2955 14.7577 13.7425 15.1327L13.7229 15.257L16.7522 17.5567L16.9619 17.5772C18.8892 15.8328 20.0006 13.266 20.0006 10.2216"
                              fill="#4285F4"
                            />
                            <path
                              d="M10.2035 20.0001C12.9585 20.0001 15.2715 19.1111 16.961 17.5778L13.741 15.1332C12.8793 15.7223 11.7229 16.1334 10.2035 16.1334C8.91317 16.126 7.65795 15.7206 6.61596 14.9747C5.57397 14.2287 4.79812 13.1802 4.39848 11.9778L4.2789 11.9878L1.12906 14.3766L1.08789 14.4888C1.93622 16.1457 3.23812 17.5387 4.84802 18.512C6.45791 19.4852 8.31234 20.0005 10.2039 20.0001"
                              fill="#34A853"
                            />
                            <path
                              d="M4.39899 11.9777C4.1758 11.3411 4.06063 10.673 4.05807 9.99996C4.06218 9.32799 4.1731 8.66075 4.38684 8.02225L4.38115 7.88968L1.19269 5.4624L1.0884 5.51101C0.372763 6.90343 0 8.4408 0 9.99987C0 11.5589 0.372763 13.0963 1.0884 14.4887L4.39899 11.9777Z"
                              fill="#FBBC05"
                            />
                            <path
                              d="M10.2039 3.86663C11.6661 3.84438 13.0802 4.37803 14.1495 5.35558L17.0294 2.59996C15.1823 0.901848 12.7364 -0.0298855 10.2039 -3.6784e-05C8.31236 -0.000477834 6.45795 0.514732 4.84805 1.48798C3.23816 2.46124 1.93625 3.85416 1.08789 5.51101L4.3875 8.02225C4.79107 6.82005 5.5695 5.77231 6.61303 5.02675C7.65655 4.28118 8.91255 3.87541 10.2039 3.86663Z"
                              fill="#EB4335"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1368_888">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      Sign in with Google
                    </button>
                  </div>

                  <div className="text-center my-4 text-[#222] text-sm ">
                    <div className="relative flex items-center">
                      <div className="flex-grow border-t border-gray-200"></div>
                      <span className="flex-shrink  text-xs mx-2 text-gray-600">or sign in with email</span>
                      <div className="flex-grow border-t border-gray-200"></div>
                    </div>
                  </div>

                  <FormProvider {...methods}>
                    <form noValidate>
                      <Input
                        label="Email"
                        name="email"
                        placeholder=""
                        inputClass="py-3 border border-gray-200 focus:border-red-100 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-opacity-20 transition-all ease-in-out duration-200"
                        rules={{
                          required: 'Email is required',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Enter a valid email',
                          },
                        }}
                      />

                      <Input
                        label="Password"
                        type="password"
                        name="password"
                        placeholder=""
                        inputClass="py-3 border border-gray-200 focus:border-red-100 focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-opacity-20 transition-all ease-in-out duration-200"
                        rules={{
                          required: 'Password is required',
                        }}
                      />
                      {error && <p className="text-red-500">{error}</p>}
                      <div className="my-4">
                        <Button text="Sign In" loading={loading} onClick={methods.handleSubmit(onLogin)} />
                      </div>
                    </form>
                  </FormProvider>
                  <div className="flex flex-wrap justify-between mb-4">
                    <Link href={routes.FORGOT_PASSWORD} passHref>
                      <a className="mb-2 mr-2 inline-block  text-viralocity text-body-color hover:text-viralocity">
                        Forgot Password?
                      </a>
                    </Link>
                    <p className="mb-2 text-base text-body-color">
                      <span className="pr-0.5"> Not a member yet? </span>
                      <Link href={routes.SIGNUP} passHref>
                        <a className="text-viralocity hover:text-viralocity">Sign up</a>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-96 lg:fixed left-0 h-screen">
                <div className="relative h-full w-full overflow-hidden">
                  <div className="flex h-full justify-start items-center">
                    <img src="/login.webp" alt="" className=" h-full object-cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
