import React from 'react'

type Props = {
    name: string;
    children: React.ReactNode;
    errors:any;
    className?: string;
}

const ErrorWrapper = ({name, children, errors, className}: Props) => {

    const hasErrors = errors && typeof errors[name] !== 'undefined';

    return (
        <>
            {children}
            
            {hasErrors && <p className="text-danger">
                {hasErrors ? errors[name].message : null}
            </p>}

        </>
    )
}

export default ErrorWrapper;