import { useFormContext } from "react-hook-form";

type Props = {
    children: (e: any) => void
}

export const ConnectForm = ({ children }: Props): any => {
 const methods = useFormContext();
 
 return children({ ...methods });
};