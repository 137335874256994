import { ConnectForm } from '@contexts/FormContext';
import ErrorWrapper from './ErrorWrapper';

//const ALLOWED_CHAR = "CHARACTERS|ALPHANUMERIC|NUMBERS|ALL"
export const enum ALLOWED_CHARACTERS {
  ALL = 'all',
  CHARACTERS = 'characters',
  ALPHANUMERIC = 'alphanumeric',
  NUMBERS = 'numbers',
}

type InputProps = {
  name: string;
  label?: string | React.ReactNode;
  inputClass?: string;
  className?: string;
  placeholder?: string;
  labelClass?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  type?: 'text' | 'number' | 'password' | 'date';
  rules?: Record<string, any>;
  allow?: ALLOWED_CHARACTERS;
  maxLength?: number;
  fieldArrayError?: any;
  prepend?: React.ReactNode;
};

const Input = (props: InputProps) => {
  const { label = '', labelClass = '', prepend = null } = props;

  return (
    <>
      <div className="mb-3">
        <label className={`${labelClass} mb-2.5 font-semibold block text-lg `}>{label}</label>
        <InputElement {...props} />
      </div>
    </>
  );
};

const InputElement = ({
  inputClass = '',
  placeholder = '',
  defaultValue = '',
  name = '',
  disabled = false,
  type = 'text',
  rules = {},
  allow = ALLOWED_CHARACTERS.ALL,
  maxLength = 500,
  fieldArrayError = null,
  prepend,
}: InputProps) => {
  const validateAllowedCharacters = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (allow === ALLOWED_CHARACTERS.ALL) return;

    if (allow === ALLOWED_CHARACTERS.CHARACTERS) {
      e.target.value = e.target.value.replace(/[^A-Za-z ]/gi, '');
    }
    if (allow === ALLOWED_CHARACTERS.ALPHANUMERIC) {
      e.target.value = e.target.value.replace(/[^A-Za-z0-9 ]/gi, '');
    }

    if (allow === ALLOWED_CHARACTERS.NUMBERS) {
      e.target.value = e.target.value.replace(/[^0-9.]/gi, '');
    }
  };

  return (
    <ConnectForm>
      {({ register, watch, formState: { errors } }) => (
        <ErrorWrapper name={name} errors={fieldArrayError ? fieldArrayError : errors}>
          <div className="flex items-center">
            {prepend && (
              <span className="h-full rounded-l-md border-1 px-3 py-2 bg-slate-100 border-slate-400 bg-gray-2 text-base text-body-color">
                {prepend}
              </span>
            )}
            <input
              disabled={disabled}
              defaultValue={defaultValue}
              type={type}
              className={`${inputClass} ${
                prepend ? 'border-start-0 rounded-l-none' : ''
              } w-full rounded-md border-1 border-slate-400 px-3 text-black outline-none focus:border-primary focus-visible:shadow-none`}
              min={0}
              autoComplete="off"
              maxLength={maxLength}
              placeholder={placeholder}
              onChange={validateAllowedCharacters}
              {...register(name, { ...rules })}
            />
          </div>
        </ErrorWrapper>
      )}
    </ConnectForm>
  );
};

export default Input;
